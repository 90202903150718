<div style="display: flex; align-items: center; justify-content: space-between; margin: 20px auto; width: 95%">
  <app-asc-autocomplete [properties]="deviceTypeSearchProps" [data]="deviceTypes$" [control]="deviceTypeControl"></app-asc-autocomplete>
  <app-asc-inline-button (btnClicked)="openUploadFileDialog()">
    Upload File
    <mat-icon inlineIcon>upload_file</mat-icon>
  </app-asc-inline-button>
</div>
<app-asc-configurable-table
  class="software-table"
  [columns]="tableColumns"
  [dataSource]="tableData"
  [columnCells]="[displayNameCol, fileName, fileType, fileVersion, fileSize, status, actions]"
  [pageables]="pageable"
  [sortProps]="sort"
  (paginatorChange)="onPagChange($event)"
  (sortChange)="onSortChange($event)"
>
</app-asc-configurable-table>

<ng-template let-tableElem="tableElem" #displayNameCol>
  {{ tableElem.displayName }}
</ng-template>
<ng-template let-tableElem="tableElem" #fileName>
  {{ tableElem.fileName }}
</ng-template>
<ng-template let-tableElem="tableElem" #fileType>
  {{ tableElem.fileType }}
</ng-template>
<ng-template let-tableElem="tableElem" #fileVersion>
  {{ tableElem.fileVersion }}
</ng-template>
<ng-template let-tableElem="tableElem" #fileSize>
  {{ tableElem.fileSize | formatFileSize }}
</ng-template>
<ng-template let-tableElem="tableElem" #status>
  <mat-icon
    [appAscTooltip]="
      tableElem.signatureValidationStatus === SignatureValidation.FAILED
        ? 'Signature Validation Failed'
        : tableElem.signatureValidationStatus === SignatureValidation.PENDING
        ? 'Signature Validation Pending'
        : tableElem.activationStatus === ActivationStatus.ACTIVE && tableElem.countOfAssignedDevices > 0
        ? 'Active and Assigned'
        : tableElem.activationStatus === ActivationStatus.ACTIVE && tableElem.countOfAssignedDevices === 0
        ? 'Active but not Assigned'
        : tableElem.activationStatus === ActivationStatus.DEACTIVATED && tableElem.countOfAssignedDevices > 0
        ? 'Deactivated but Assigned'
        : tableElem.activationStatus === ActivationStatus.DEACTIVATED
        ? 'Deactivated'
        : 'Unkown Status'
    "
    [ngClass]="
      tableElem.signatureValidationStatus === SignatureValidation.FAILED
        ? 'icon-deactivated'
        : tableElem.signatureValidationStatus === SignatureValidation.PENDING
        ? 'icon-validation-pending'
        : tableElem.activationStatus === ActivationStatus.ACTIVE && tableElem.countOfAssignedDevices > 0
        ? 'icon-active-assigned'
        : tableElem.activationStatus === ActivationStatus.ACTIVE && tableElem.countOfAssignedDevices === 0
        ? 'icon-active-unassigned'
        : tableElem.activationStatus === ActivationStatus.DEACTIVATED && tableElem.countOfAssignedDevices > 0
        ? 'icon-deactivated-assigned'
        : tableElem.activationStatus === ActivationStatus.DEACTIVATED
        ? 'icon-deactivated'
        : 'icon-not-available'
    "
  >
    {{
      tableElem.activationStatus === ActivationStatus.ACTIVE && tableElem.countOfAssignedDevices > 0
        ? 'done_all'
        : tableElem.activationStatus === ActivationStatus.ACTIVE && tableElem.countOfAssignedDevices === 0
        ? 'done'
        : tableElem.activationStatus === ActivationStatus.DEACTIVATED && tableElem.countOfAssignedDevices > 0
        ? 'remove_done'
        : tableElem.activationStatus === ActivationStatus.DEACTIVATED
        ? 'block'
        : 'question_mark'
    }}
  </mat-icon>
</ng-template>
<ng-template let-tableElem="tableElem" #actions>
  <app-asc-inline-button (btnClicked)="detailsButtonClick(tableElem)">
    Details
    <mat-icon inlineIcon>info</mat-icon>
  </app-asc-inline-button>
  <app-asc-inline-button [matMenuTriggerFor]="actionsMenu" [matMenuTriggerData]="{ dlc: tableElem }">
    Actions
    <mat-icon>expand_more</mat-icon>
  </app-asc-inline-button>
  <app-asc-icon-button
    [disabled]="tableElem.activationStatus === ActivationStatus.ACTIVE || tableElem.countOfAssignedDevices !== 0"
    (btnClicked)="openDeleteConfirmDialog(tableElem)"
  >
    delete
  </app-asc-icon-button>
</ng-template>

<mat-menu #actionsMenu="matMenu">
  <ng-template matMenuContent let-dlc="dlc">
    <button mat-menu-item (click)="openEditFileDialog(dlc)" appAscTooltip="Show details for this downloadable content.">Edit</button>
    <button
      mat-menu-item
      [disabled]="dlc.activationStatus === ActivationStatus.DEACTIVATED"
      (click)="deactivateAction(dlc)"
      appAscTooltip="Select devices to receive downloadable content."
    >
      Deactivate
    </button>
    <button
      mat-menu-item
      [disabled]="dlc.activationStatus === ActivationStatus.ACTIVE"
      (click)="activateAction(dlc)"
      appAscTooltip="Activate this downloadable content."
    >
      Activate
    </button>
    <button
      mat-menu-item
      [disabled]="dlc.activationStatus === ActivationStatus.DEACTIVATED
        || dlc.signatureValidationStatus === SignatureValidation.PENDING
        || dlc.signatureValidationStatus === SignatureValidation.FAILED"
      (click)="deliverAction(dlc)"
      appAscTooltip="Select devices to receive downloadable content."
    >
      Deliver
    </button>
  </ng-template>
</mat-menu>
