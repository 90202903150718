import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { ToastsService } from 'src/app/error-handling/services/toasts.service';
import { AppStorageService } from 'src/app/shared/app-storage.service';
import { ConfigPageEvent } from 'src/app/shared/components/asc-configurable-table/asc-configurable-table.component';
import { AscConfirmDialogComponent } from 'src/app/shared/components/asc-confirm-dialog/asc-confirm-dialog.component';
import { AscConfirmDialogData } from 'src/app/shared/interfaces/AscConfirmDialogData';
import { ConfigureableTableColumn } from 'src/app/shared/interfaces/ConfigureableTableColumn';
import { DialogResponse } from 'src/app/shared/interfaces/DialogResponse';
import { StorageOperations } from 'src/app/shared/interfaces/StorageOperations';
import { SharedApiService } from 'src/app/shared/services/shared-api.service';
import { DeviceType } from '../../interfaces/DeviceType';
import { DeviceTypeDataSource } from '../../interfaces/DeviceTypeDataSource';
import { DeviceApiService } from '../../services/device-api.service';
import { AddDeviceTypeDialogComponent } from '../add-device-type-dialog/add-device-type-dialog.component';
import { UpdateDeviceTypeDialogComponent } from '../update-device-type-dialog/update-device-type-dialog.component';

@Component({
  selector: 'app-device-type',
  templateUrl: './device-type.component.html',
  styleUrls: ['./device-type.component.css'],
})
export class DeviceTypeComponent implements OnInit, StorageOperations, OnDestroy {
  searchControl = new FormControl('');
  dataSource: DeviceTypeDataSource;
  columns: ConfigureableTableColumn<DeviceType>[] = [
    {
      sortable: true,
      name: 'deviceType',
      header: 'Device Type',
      tooltip: 'A name for the device type',
    },
    {
      sortable: true,
      name: 'modelType',
      header: 'Model Type',
      tooltip: 'The device model type',
    },
    {
      sortable: true,
      name: 'updateDhs',
      header: 'Update DHS',
      tooltip:
        'Describes the default behavior whether informs DHS that a device is connected, disconnected,  attached a satellite , detached a satellite or not.',
    },
    {
      sortable: true,
      name: 'unpackUploadedArchives',
      header: 'Unpack Archives',
      tooltip: 'Describes whether the device should extract uploaded archives as default behavior or not.',
    },
    {
      sortable: true,
      name: 'specialProcessingPattern',
      header: 'Special processing pattern',
      tooltip: 'Specifies the regex used to match password protected archives.',
    },
    {
      sortable: true,
      name: 'gateKeeperConnection',
      header: 'Gate-Keeper-Connection',
      tooltip: 'Describes if this device type supports GateKeeper as remote session type.',
    },
    {
      sortable: true,
      name: 'quickConnection',
      header: 'Quick Connection',
      tooltip: 'Describes if this device type supports QuickConnection as remote session type.',
    },
    {
      sortable: true,
      name: 'sMaxMatch',
      header: 'SMAX Match',
      tooltip: 'The SMAX match attribute should include true/false values which identified whether the ASC device type exists on SMAX or not.',
    },
    {
      sortable: true,
      name: 'inUse',
      header: 'In Use ',
      tooltip: 'Specifies the number of devices that are of this device type.',
    },
    {
      sortable: true,
      name: 'createdOnDate',
      header: 'Created On',
      tooltip: 'The point in time when the device type was created.',
    },
    {
      sortable: false,
      name: 'actions',
      header: 'Action',
    },
  ];

  pageables: ConfigPageEvent = {
    pageIndex: 0,
    pageSize: 10,
    pageSizeOptions: [10, 20, 50],
  };
  sort: Sort;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private sharedApiService: SharedApiService,
    private deviceApiService: DeviceApiService,
    private store: AppStorageService,
    private toasts: ToastsService
  ) {
    this.dataSource = new DeviceTypeDataSource(this.sharedApiService);
    this.getStorageData();

    window.onbeforeunload = () => this.saveStorageData();
  }

  ngOnInit(): void {
    this.loadData();
  }

  getStorageData(): void {
    this.pageables = {
      ...this.pageables,
      pageSize: this.store.getValue('devicetypes_pagination_pagesize') || 10,
    };
    this.sort = {
      active: this.store.getValue('devicetypes_sort_column') || '',
      direction: this.store.getValue('devicetypes_sort_direction') || '',
    };
  }
  saveStorageData(): void {
    this.store.setValue('devicetypes_pagination_pagesize', this.pageables.pageSize, 'number', true);
    this.store.setValue('devicetypes_sort_column', this.sort.active, 'string', true);
    this.store.setValue('devicetypes_sort_direction', this.sort.direction, 'string', true);
  }

  pagChange(evt: PageEvent) {
    this.pageables = {
      ...this.pageables,
      pageSize: evt.pageSize,
      pageIndex: evt.pageIndex,
    };
    this.loadData();
  }
  sortChange(evt: Sort) {
    this.sort = evt;
    this.loadData();
  }
  searchDeviceTypes() {
    this.loadData();
  }

  loadData() {
    this.dataSource.loadDeviceTypes(
      '',
      this.searchControl.value || '',
      this.pageables.pageSize,
      this.pageables.pageIndex,
      this.sort?.active && this.sort.direction ? this.sort.active : '',
      this.sort.direction || ''
    );
  }

  goHome(): void {
    this.router.navigate(['devicelist']);
  }

  // method executed from UI when Delete button is selected
  openAddDeviceTypeDialog(): void {
    const dialogRef = this.dialog.open<AddDeviceTypeDialogComponent, unknown, Partial<DialogResponse>>(AddDeviceTypeDialogComponent, {});
    dialogRef.afterClosed().subscribe((resp) => {
      if (resp?.status === 'SUCCESS') {
        this.loadData();
      }
    });
  }

  openDeleteConfirmDialog(value: DeviceType): void {
    const dialogConfig: MatDialogConfig<AscConfirmDialogData<unknown, unknown>> = {
      data: {
        actionLabel: 'Delete',
        title: 'Delete Device Type',
        dialogType: 'warning',
        action: 'parent-action',
        message: `Delete Device Type ${value.deviceType}?`,
      },
    };

    const dialogRef = this.dialog.open(AscConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((resp) => {
      if (resp.status !== 'ACTION') {
        return;
      }
      this.deviceApiService.deleteDeviceType(value.deviceTypeId, value.deviceType).subscribe({
        next: () => {
          this.toasts.raise({ message: 'Deleted Device Type ' + value.deviceType, title: 'Delete Device Type' }, 'SUCCESS');
          this.loadData();
        },
        error: (err) => {
          this.toasts.raise({ message: 'Could not delete Device Type' + value.deviceType, title: 'Delete Device Type', error: err }, 'ERROR');
        },
      });
    });
  }

  openEditDialog(deviceType: DeviceType): void {
    const dialogRef = this.dialog.open(UpdateDeviceTypeDialogComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '40%',
      width: '25%',
      data: {
        deviceType: deviceType,
      },
    });
    dialogRef.afterClosed().subscribe((resp) => {
      if (resp?.status === 'SUCCESS') {
        this.loadData();
      }
    });
  }

  ngOnDestroy(): void {
    this.saveStorageData();
  }
}
